import React from "react";

//style
import './Event.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";


function Event() {

    return (
        <div className="container-fluid">
          <div className="row">
             <Header />
              <div className="home ">
                <div className="top align-items-center justify-content-center d-flex">  
                    {/* <img src="/"></img>           */}
                  <div className="toptext p-3">
                <h1 className="mt-1 ">
                OUR UPCOMING
                </h1>
              <p className="mt-3"> 
              EVENTS <span> 2024</span>
              </p>
                  </div>
                </div>
                <div className="event p-3 align-items-center justify-content-center mt-5">
                    <div className="eventdetails d-flex flex-wrap  align-items-center justify-content-center ">
                    <div className="date">
                        <p className="mt-3">Dec 27</p>
                        </div>
                        <div className="details  p-2">
                        <p className="mt-3">
                        DesignCave at One: Honoring the Journey, Inspiring the Future. </p>
                        <p className="mt-3">
                        Guest Speaker: Name will be updated. </p>
                        </div>
                    </div>
                    <div className="horizontal-line w-50 d-flex align-items-center justify-content-center"></div>
                </div>

                <h1 className="d-flex align-items-center justify-content-center mt-5">2025</h1>

                <div className="event align-items-center justify-content-center mt-5">
                    <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center ">
                    <div className="details p-2">
                    <p className="mt-3 ">
                        Fresh Perspectives: Starting 2025 with Design in Focus. </p>
                        <p className="mt-3">
                        Guest Speaker: Name will be updated. </p>
                        </div>
                        <div className="date2">
                        <p className="mt-3">JAN 31</p>
                        </div>
                    </div>
                    <div className="horizontal-line"></div>
                </div>

                <div className="event align-items-center justify-content-center mt-5">
                    <div className="eventdetails3 d-flex flex-wrap align-items-center justify-content-center ">
                    <div className="date3">
                        <p className="mt-3">FEB 22</p>
                        </div>
                        <div className="details p-2">
                        <p className="mt-3">
                        Designing with Heart: The Human Side of UI/UX. </p>
                        <p className="mt-3">
                        Guest Speaker: Name will be updated. </p>
                        </div>
                    </div>
                    <div className="horizontal-line"></div>
                </div>

                    <div className="check d-flex flex-wrap m-5 justify-content-center">
                    <button id="" className="p-2">Check back for more event updates</button>
                    </div>
            
                </div>
                <Footer/>
              </div>
    
          </div>
      );
}

export default Event